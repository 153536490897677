<template>
  <body id="poster">
  <el-form class="login-container" label-position="left"
           label-width="0px" ref="loginForm" :model="loginForm" :rules="rules">
    <h3 class="login_title">亿企富财务系统</h3>
    <el-form-item prop="username">
      <el-input type="text" v-model="loginForm.username"
                auto-complete="off" placeholder="账号">
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input type="password" v-model="loginForm.password"
                auto-complete="off" placeholder="密码">
      </el-input>
    </el-form-item>

    <el-form-item>
      <el-radio-group v-model="loginForm.role">
        <el-radio label="业务员">业务员</el-radio>
        <el-radio label="操作员">操作员</el-radio>
        <el-radio label="管理员">管理员</el-radio>

      </el-radio-group>
    </el-form-item>

<!--    <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;right: 130px">记住密码</el-checkbox>-->

    <el-form-item style="width: 100%">
      <el-button type="primary" style="width: 100%;background: #707070;border: none" v-on:click="login">登录</el-button>
    </el-form-item>

  </el-form>
  <div>
    <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022130356号-1</a>
  </div>
  </body>

</template>
<script>
export default {
  name: 'Login',
  data () {
    return {
      loginForm: {
        username: '',
        password: '',
        role: ''
      },
      rules:{
        username:[{required:true, message:'请输入用户名', trigger:'blur'}],
        password:[{required:true, message:'请输入密码', trigger:'blur'}]
      },
      loading: false,
      responseResult: []
    }
  },
  methods: {
    login () {
      let _this = this;

      this.$refs.loginForm.validate((valid) => {
        if (valid){
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          axios.get('http://1.14.190.205:8088/login',{params:_this.loginForm}).then(function (response){
            if(response.data){
              loading.close();
              localStorage.setItem('access-admin',JSON.stringify(response.data.token))
              if(response.data.role === "管理员"){
                _this.$router.replace({path:'/AdminPage'})
              }
              if(response.data.role === "操作员"){
                _this.$router.replace({path:'/OperatorPage'})
              }
              if(response.data.role === "业务员"){
                _this.$router.replace({path:'/SalesmanPage'})
              }
              if(response.data.code === -1||response.data.code === -2){
                _this.$alert('账号或密码错误，请重新登录','',{
                  confirmButtonText: '确定',
                  callback: action => {
                    _this.$router.push('/')
                  }
                });
              }
              if(response.data.code === -3){
                _this.$alert('登录角色错误，请重新登录','',{
                  confirmButtonText: '确定',
                  callback: action => {
                    _this.$router.push('/')
                  }
                });
              }

            }
            else{
              _this.$alert('账号或密码错误，请重新登录','',{
                confirmButtonText: '确定',
                callback: action => {
                  _this.$router.push('/')
                }
              });
            }
          })
        }else {
          console.log('error submit!');
          return false;
        }
      })
    }
  }
}
</script>
<style>
#poster {
  background:url("https://s1.ax1x.com/2022/08/01/vkxD78.jpg") no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  background-size: cover;
  position: fixed;
}
body{
  margin: 0px;
}
.login-container {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 90px auto;
  width: 350px;
  padding: 35px 35px 35px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

.login_title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}

.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}

</style>
