<template>
  <el-container class="home_container">
    <el-header class="home_header">
      <div class="home_title">亿企富财税系统-管理员</div>
      <div class="home_userinfoContainer">
        <el-dropdown>
                  <span class="el-dropdown-link home_userinfo">
                    {{admin.name}}<i class="el-icon-arrow-down el-icon--right home_userinfo"></i>
                  </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>

    <el-container>
      <el-aside class="home_aside" width="200px">
        <el-menu router>
          <el-submenu index="1">
            <template slot="title"><i class="el-icon-s-platform"></i>账号模块</template>
            <el-menu-item index="/AccountAdd" :class="$route.path=='/AccountAdd'?'is-active':''">
              <i class="el-icon-folder-add"></i>添加账号
            </el-menu-item>
            <el-menu-item index="/AccountManager" :class="$route.path=='/AccountManager'?'is-active':''">
              <i class="el-icon-document-copy"></i>账号管理
            </el-menu-item>
          </el-submenu>

          <el-submenu index="2">
            <template slot="title"><i class="el-icon-user-solid"></i>业务模块</template>

            <el-menu-item index="/BusinessAdd" :class="$route.path=='/BusinessAdd'?'is-active':''">
              <i class="el-icon-folder-add"></i>添加业务
            </el-menu-item>
            <el-menu-item index="/BusinessManager" :class="$route.path=='/BusinessManager'?'is-active':''">
              <i class="el-icon-document-copy"></i>业务管理
            </el-menu-item>
            <el-menu-item index="/AuditBusiness" :class="$route.path=='/AuditBusiness'?'is-active':''">
              <i class="el-icon-document-copy"></i>已审核业务
            </el-menu-item>

          </el-submenu>


        </el-menu>

      </el-aside>

      <el-container>
        <el-main>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">登录</el-breadcrumb-item>
            <el-breadcrumb-item v-text="this.$router.currentRoute.name"></el-breadcrumb-item>
          </el-breadcrumb>
          <router-view></router-view>
        </el-main>
        <el-footer class="home_footer">2022 © YQF</el-footer>
      </el-container>

    </el-container>

  </el-container>
</template>
<script>
export default{
  methods: {
    logout(){
      let _this = this;
      this.$confirm('确定退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        localStorage.removeItem('systemAdmin')
        localStorage.removeItem('access-admin')
        _this.$router.replace({path: '/login'})
      })
    }
  },
  data(){
    return {
      admin:''
    }
  },
  // created() {
  //     let admin = JSON.parse(window.localStorage.getItem('systemAdmin'))
  //     this.admin = admin
  // }
}
</script>
<style>
.home_container {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}

.home_header {
  background-color: #2B2B2B;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home_title {
  color: #C2C2C2;
  font-size: 22px;
  display: inline;
}

.home_userinfo {
  color: #fff;
  cursor: pointer;
}

.home_userinfoContainer {
  display: inline;
  margin-right: 20px;
}

.home_aside {
  background-color: #FFFFFF;
}

.home_footer {
  background-color: #FFFFFF;
  color: #000000;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
}
</style>
