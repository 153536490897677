<template>
  <div style="margin-top: 60px;margin-left:330px;width: 300px;height: 500px;border: 0px solid red;" >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="账号" prop="username">
        <el-input v-model="ruleForm.username"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="ruleForm.password"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="角色">
        <div style="width: 280px;height: 30px;">
          <template>
            <el-radio v-model="ruleForm.role" label="业务员">业务员</el-radio>
            <el-radio v-model="ruleForm.role" label="操作员">操作员</el-radio>
            <el-radio v-model="ruleForm.role" label="管理员">管理员</el-radio>
          </template>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>

<script>
export default {
  data() {
    return {
      category:null,
      ruleForm: {
        username: '',
        password: '',
        name: '',
        role: '业务员'
      },
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      const _this = this
      this.$refs[formName].validate((valid) => {
        let admin = JSON.parse(window.localStorage.getItem('access-admin'))
        if (valid) {
          console.log(_this.ruleForm);
          console.log(admin);
          let formData = _this.ruleForm;
          axios.post('http://1.14.190.205:8088/accountAdd',_this.ruleForm,{
            headers:{
              'token': admin
            }
          }).then(function (resp) {
            if(resp.data.code == 200){
              _this.$alert(_this.ruleForm.username+'添加成功', '', {
                confirmButtonText: '确定',
                callback: action => {
                  _this.$router.push('/AccountManager')
                }
              });
            }else if(resp.data.code == 400 || resp.data.code == 401){
              _this.$alert('账号越权,请重新登录','',{
                confirmButtonText: '确定',
                callback: action => {
                  _this.$router.push('/login')
                }
              });
            }
          })
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
