import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from "@/views/Login";
import AdminPage from "@/views/AdminPage";
import AccountAdd from "@/views/AccountAdd";
import AccountManager from "@/views/AccountManager";
import AccountUpdata from "@/views/AccountUpdata";
import oldBusinessAdd from "@/views/oldBusinessAdd";
import BusinessAdd from "@/views/BusinessAdd";
import BusinessManager from "@/views/BusinessManager";
import BusinessUpdate from "@/views/BusinessUpdate";
import AuditBusiness from "@/views/AuditBusiness";
import OperatorPage from "@/views/OperatorPage";
import FinishBusiness from "@/views/FinishBusiness";
import NotFinishBusiness from "@/views/NotFinishBusiness";
import OperatorBusinessUpdate from "@/views/OperatorBusinessUpdate";
import SalesmanPage from "@/views/SalesmanPage";
import SalesmanBusinessUpdate from "@/views/SalesmanBusinessUpdate";
import SalesmanBusiness from "@/views/SalesmanBusiness";
import SalesmanAddBusiness from "@/views/SalesmanAddBusiness";
import HandoverTablePage from "@/views/HandoverTablePage";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/login',
    name: 'home',
    component: Login
  },
  {
    path:'/SalesmanPage',
    name: '业务员',
    component: SalesmanPage,
    redirect: '/SalesmanBusiness',
    children: [
      {
        path: '/SalesmanAddBusiness',
        name: '业务员添加业务',
        component: SalesmanAddBusiness
      },
      {
        path: '/SalesmanBusiness',
        name: '业务管理',
        component: SalesmanBusiness
      },
      {
        path: '/SalesmanBusinessUpdate',
        name: '业务员编辑页面',
        component: SalesmanBusinessUpdate
      },
      {
        path: '/HandoverTablePage',
        name: '业务交接单',
        component: HandoverTablePage
      }
    ]
  },
  {
    path: '/OperatorPage',
    name: '操作员',
    component: OperatorPage,
    redirect: '/NotFinishBusiness',
    children: [
      {
        path: '/FinishBusiness',
        name: '已完成的业务',
        component: FinishBusiness
      },
      {
        path: '/NotFinishBusiness',
        name: '未完成的业务',
        component: NotFinishBusiness
      },
      {
        path: '/OperatorBusinessUpdate',
        name: '操作员编辑业务',
        component: OperatorBusinessUpdate
      }
    ]
  },
  {
    path: '/AdminPage',
    name: '管理员',
    component: AdminPage,
    redirect: '/AccountAdd',
    children:[
      {
        path: '/AccountAdd',
        name: '添加账号',
        component: AccountAdd
      },
      {
        path: '/AccountManager',
        name: '账号管理',
        component: AccountManager
      },
      {
        path: '/AccountUpdata',
        name: '账号编辑',
        component: AccountUpdata
      },
      {
        path: '/oldBusinessAdd',
        name: '添加业务',
        component: oldBusinessAdd
      },
      {
        path: '/BusinessAdd',
        name: '添加业务',
        component: BusinessAdd
      },
      {
        path: '/BusinessManager',
        name: '业务管理',
        component: BusinessManager
      },
      {
        path: '/BusinessUpdate',
        name: '业务编辑',
        component: BusinessUpdate
      },
      {
        path: '/AuditBusiness',
        name: '已审核业务',
        component: AuditBusiness
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//token路由守卫
router.beforeEach((to, from, next) => {
  //验证是否为登录页面
  if(to.path.startsWith('/login'||'/')){
    //如果是登录页面则
    window.localStorage.removeItem('access-admin')
    next()
  }else{
    //如果不是登录页面
    //拿到access-admin对象
    let admin = JSON.parse(window.localStorage.getItem('access-admin'))
    //如果对象为空，则说明还没有登陆过，跳转回登录页面
    if(!admin){
      next({path:'/login'})
    }else {
      //如果对象存在则发送请求验证token是否过期,通过请求头传输token
      axios({
        url:'http://localhost:8088/checkToken',
        method:'get',
        headers:{
          token:admin.token
        }
      }).then((response) => {
        // console.log(response.data)
        //校验失败后返回登录页面
        if(response.data){
          console.log("没有权限")
          next({path:'/login'})
        }
      })
      next();
    }
  }
})

export default router
