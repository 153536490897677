<template>
  <div style="margin-top: 60px;margin-left:80px;border: 0px solid red;" >
    <el-form style="margin-left: -40px" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="字段：" prop="key">
        <el-select v-model="ruleForm.key" style="width: 160px;float: left" placeholder="请选择字段">
          <el-option label="账号" value="username"></el-option>
          <el-option label="姓名" value="name"></el-option>
          <el-option label="角色" value="role"></el-option>
        </el-select>
      </el-form-item>
      <div style="border: 0px solid red;width: 400px;height: 60px;position: relative;top: -64px;left: 270px">
        <el-form-item label="值：">
          <el-input v-model="ruleForm.value" placeholder="请输入关键字" style="width: 160px;"></el-input>
          <el-button type="primary" icon="el-icon-search" style="position: relative;left: 10px;" @click="submitForm('ruleForm')">搜索</el-button>
        </el-form-item>
      </div>
    </el-form>

    <el-table
        :data="tableData"
        border
        stripe
        style="width: 100%;position: relative;top:-30px">
      <el-table-column
          fixed
          sortable
          prop="id"
          label="ID"
          width="160">
      </el-table-column>
      <el-table-column
          prop="username"
          label="账号"
          width="200">
      </el-table-column>
      <el-table-column
          prop="password"
          label="密码"
          width="200">
      </el-table-column>
      <el-table-column
          prop="name"
          label="姓名"
          width="200">
      </el-table-column>
      <el-table-column
          prop="role"
          label="角色"
          width="200">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="edit(scope.row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="margin-top: 20px;float: right"
                   background
                   layout="prev, pager, next"
                   :page-size="pageSize"
                   :total="total"
                   :current-page.sync="currentPage"
                   @current-change="page">
    </el-pagination>
  </div>

</template>

<script>
export default {
  data() {
    return {
      tableData:null,
      currentPage: 1,
      pageSize: 5,
      total: '',
      key: '',
      value: '',
      ruleForm: {
        key: '',
        value: '',
        page: '',
        size: 5
      },
      rules: {
        key: [
          { required: true, message: '请选择字段', trigger: 'change' }
        ]
      }
    }
  },
  methods:{
    submitForm(formName) {
      const _this = this
      let admin = JSON.parse(window.localStorage.getItem('access-admin'))
      //让翻页复原
      _this.currentPage = 1
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this
          _this.ruleForm.page = _this.currentPage
          axios.get('http://1.14.190.205:8088/search',{
            params:_this.ruleForm,
            headers:{
              'token': admin
            }}).then(function (resp) {
            _this.tableData = resp.data.data
            _this.total = resp.data.total
          })
        }
      });
    },
    page(currentPage){
      const _this = this
      let admin = JSON.parse(window.localStorage.getItem('access-admin'))
      if(_this.ruleForm.value == ''){
        axios.get('http://1.14.190.205:8088/list/'+currentPage+'/'+_this.pageSize, {
          headers: {
            'token': admin
          }
        }).then(function (resp) {
          _this.tableData = resp.data.data
          _this.total = resp.data.total
        })
      } else {
        _this.ruleForm.page = _this.currentPage
        axios.get('http://1.14.190.205:8088/search',{
          params:_this.ruleForm,
          headers:{
            'token': admin
          }
        }).then(function (resp) {
          _this.tableData = resp.data.data
          _this.total = resp.data.total
        })
      }
    },
    edit(row){
      this.$router.push('/AccountUpdata?id='+row.id)
    },
    del(row) {
      const _this = this
      let admin = JSON.parse(window.localStorage.getItem('access-admin'))
      this.$confirm('确认删除【'+row.username+'】吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.delete('http://1.14.190.205:8088/deleteById/'+row.id,{
          headers: {
            'token': admin
          }
        }).then(function (resp) {
          if(resp.data.code==200){
            _this.$alert('【'+row.username+'】已删除', '', {
              confirmButtonText: '确定',
              callback: action => {
                _this.$router.go(0)
              }
              // callback: action => {
              //   location.reload()
              // }
            });
          }
        });
      });
    }
  },
  created() {
    let admin = JSON.parse(window.localStorage.getItem('access-admin'))
    const _this = this
    axios.get('http://1.14.190.205:8088/list/1/'+_this.pageSize,{
      headers:{
        'token': admin
      }
    }).then(function (resp) {
      console.log(resp.data)
      _this.tableData = resp.data.data
      _this.total = resp.data.total
    })
  }
}
</script>