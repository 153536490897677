<template>
  <div style="margin-top: 60px;margin-left:330px;width: 1000px;height: 500px;border: 0px solid #434343;">
    <el-row :gutter="15">
      <el-form ref="formData" :model="formData" :rules="rules" size="" label-width="100px">
        <el-col :span="24">
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="业务编号" prop="businessId">
                <el-input v-model="formData.businessId" clearable readonly
                          prefix-icon='el-icon-s-order' :style="{width: '100%'}"></el-input>
              </el-form-item>
              <el-form-item label="公司名" prop="companyName">
                <el-input v-model="formData.companyName" placeholder="请输入公司名" clearable
                          prefix-icon='el-icon-s-order' :style="{width: '100%'}"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="签单人" prop="orderSigner">
                <el-input v-model="formData.orderSigner" placeholder="请输入签单人" clearable
                          prefix-icon='el-icon-s-order' :style="{width: '100%'}"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-form-item label="业务类型" prop="businessContent">
            <el-input v-model="formData.businessContent" placeholder="请输入业务类型" clearable :style="{width: '100%'}">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="金额" prop="amount">
            <el-input v-model="formData.amount"  placeholder="请输入金额" clearable :style="{width: '100%'}">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="下单时间" prop="orderTime">
            <el-date-picker v-model="formData.orderTime"  format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            :style="{width: '100%'}" placeholder="请选择下单时间" clearable></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="业务状态" prop="businessState">
            <el-select disabled v-model="formData.businessState" placeholder="请选择业务状态" clearable
                       :style="{width: '100%'}">
              <el-option v-for="(item, index) in businessStateOptions" :key="index" :label="item.label"
                         :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="业务进度" prop="businessProgress">
            <el-input readonly v-model="formData.businessProgress" placeholder="请输入业务进度" clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否收款" prop="collectionState">
            <el-select disabled v-model="formData.collectionState" placeholder="请选择是否收款" clearable :style="{width: '100%'}">
              <el-option v-for="(item, index) in collectionStateOptions" :key="index" :label="item.label"
                         :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="经办人" prop="managerName">
                <el-input v-model="formData.managerName" placeholder="请输入经办人" clearable
                          :style="{width: '100%'}"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-form-item label="业务账号" prop="businessUsername">
            <el-input v-model="formData.businessUsername" placeholder="请输入业务账号" clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="业务密码" prop="businessPassword">
            <el-input v-model="formData.businessPassword" placeholder="请输入业务密码" clearable
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="formData.remark" type="textarea"  placeholder="备注"
                      :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="业务审核" prop="businessAudit">
            <el-select disabled v-model="formData.businessAudit"  placeholder="请选择业务审核" clearable :style="{width: '100%'}">
              <el-option v-for="(item, index) in businessAuditOptions" :key="index" :label="item.label"
                         :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item size="large">
            <el-button type="primary" @click="submitForm('formData')">提交</el-button>
            <el-button @click="resetForm('formData')">重置</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>


export default {
  data() {
    return {
      formData: {
        companyName: '',
        orderSigner: '',
        businessContent: '',
        amount: '',
        orderTime: null,
        businessState: '',
        businessProgress: '',
        collectionState: '',
        managerName: '',
        businessUsername: '',
        businessPassword: '',
        remark: '',
        businessAudit: '',
      },
      rules: {
        companyName: [{
          required: true,
          message: '请输入公司名',
          trigger: 'blur'
        }],
        businessContent: [{
          required: true,
          message: '请输入公司名称',
          trigger: 'blur'
        }],
        orderSigner: [{
          required: true,
          message: '请输入签单人',
          trigger: 'blur'
        }],
        amount: [{
          required: true,
          message: '请输入金额',
          trigger: 'blur'
        }],
        orderTime: [{
          required: true,
          message: '请选择下单时间',
          trigger: 'change'
        }],
        businessState: [{
          required: true,
          message: '请选择业务状态',
          trigger: 'change'
        }],
        businessProgress: [],
        collectionState: [{
          required: true,
          message: '请选择是否收款',
          trigger: 'change'
        }],
        managerName: [],
        businessUsername: [],
        businessPassword: [],
        remark: [],
        businessAudit: [{
          required: true,
          message: '请选择业务审核',
          trigger: 'change'
        }],
      },
      businessStateOptions: [{
        "label": "未完成",
        "value": "未完成"
      }, {
        "label": "已完成",
        "value": "已完成"
      }],
      collectionStateOptions: [{
        "label": "未收款",
        "value": "未收款"
      }, {
        "label": "已收款",
        "value": "已收款"
      }],
      businessAuditOptions: [{
        "label": "未审核",
        "value": "未审核"
      }, {
        "label": "已审核",
        "value": "已审核"
      }],
    }
  },
  methods: {
    submitForm(formName) {
      const _this = this
      this.$refs[formName].validate((valid) => {
        let admin = JSON.parse(window.localStorage.getItem('access-admin'))
        if (valid) {
          console.log(_this.formData);
          console.log(admin);
          let formData1 = _this.formData;
          axios.post('http://1.14.190.205:8088/upBusiness',_this.formData,{
            headers:{
              'token': admin
            }
          }).then(function (resp) {
            if(resp.data.code == 200){
              _this.$alert(_this.formData.companyName+'修改成功', '', {
                confirmButtonText: '确定',
                callback: action => {
                  _this.$router.push('/SalesmanPage')
                }
              });
            }else if(resp.data.code == 400 || resp.data.code == 401){
              _this.$alert('账号越权,请重新登录','',{
                confirmButtonText: '确定',
                callback: action => {
                  _this.$router.push('/login')
                }
              });
            }
          })
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  created() {
    const _this = this;
    let admin = JSON.parse(window.localStorage.getItem('access-admin'))
    console.log(_this.$route.query.id)
    axios.get('http://1.14.190.205:8088/findBusinessById/'+_this.$route.query.id,{
      headers:{
        'token': admin
      }
    }).then(function (resp){
      console.log(resp.data);
      _this.formData = resp.data.business;
    })
  }
}
</script>
