<template>
  <div style="margin-top: 60px;margin-left:80px;border: 0px solid red;" >
    <el-form style="margin-left: -40px" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="字段：" prop="key">
        <el-select v-model="ruleForm.key" style="width: 160px;float: left" placeholder="请选择字段">
<!--          传入后端valueu要和数据库的字段相同-->
          <el-option label="业务编号" value="business_id"></el-option>
          <el-option label="公司名称" value="company_name"></el-option>
          <el-option label="业务类型" value="business_content"></el-option>
          <el-option label="业务状态" value="business_state"></el-option>
          <el-option label="是否收款" value="collection_state"></el-option>
          <el-option label="签单人" value="order_signer"></el-option>
          <el-option label="经办人" value="manager_name"></el-option>
        </el-select>
      </el-form-item>
      <div style="border: 0px solid red;width: 1000px;height: 60px;position: relative;top: -64px;left: 270px">
        <el-form-item label="值：">
          <el-input v-model="ruleForm.value" placeholder="请输入关键字" style="width: 160px;"></el-input>
          <span> 日期范围</span>
          <el-date-picker
              v-model="ruleForm.day1"
              type="date"
              placeholder="选择起始日期">
          </el-date-picker>
          <el-date-picker
              v-model="ruleForm.day2"
              type="date"
              placeholder="选择结束日期">
          </el-date-picker>
          <el-button type="primary" icon="el-icon-search" style="position: relative;left: 10px;" @click="submitForm('ruleForm')">搜索</el-button>
          <el-button @click="handleExport">下载表格</el-button>
        </el-form-item>
      </div>
    </el-form>

    <el-table
        ref="myTable"
        id="myTable"
        :data="tableData"
        border
        stripe
        :cell-style="addClass"
        show-summary
        :summary-method="getSummaries"
        max-height="520"
        style="width: 100%;position: relative;top:-30px;">
      <el-table-column
          fixed
          sortable
          prop="businessId"
          label="业务编号"
          width="120">
      </el-table-column>
      <el-table-column
          prop="companyName"
          label="公司名称"
          width="250">
      </el-table-column>
      <el-table-column
          prop="orderSigner"
          label="签单人"
          width="100">
      </el-table-column>
      <el-table-column
          prop="businessContent"
          label="业务类型"
          width="160">
      </el-table-column>
      <el-table-column
          sortable
          prop="amount"
          label="金额"
          width="120">
      </el-table-column>
      <el-table-column
          sortable
          prop="orderTime"
          label="下单时间"
          width="150">
      </el-table-column>
      <el-table-column
          prop="businessState"
          label="业务状态"
          width="120">
      </el-table-column>
      <el-table-column
          prop="businessProgress"
          label="业务进度"
          width="200">
      </el-table-column>
      <el-table-column
          prop="collectionState"
          label="是否收款"
          width="120">
      </el-table-column>
      <el-table-column
          prop="managerName"
          label="经办人"
          width="100">
      </el-table-column>
      <el-table-column
          prop="businessUsername"
          label="业务密码"
          width="150">
      </el-table-column>
      <el-table-column
          prop="businessPassword"
          label="业务密码"
          width="150">
      </el-table-column>
      <el-table-column
          prop="businessAudit"
          label="业务审核"
          width="120">
      </el-table-column>
      <el-table-column
          prop="remark"
          label="备注"
          width="200">
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="240">

        <template slot-scope="scope">
          <el-button
              size="mini"
              type="success"
              @click="finish(scope.row)"
              v-if="scope.row.businessAudit === '未审核'">完成</el-button>
          <el-button
              size="mini"
              @click="edit(scope.row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination style="margin-top: 20px;float: right"
                   background
                   layout="total, sizes, prev, pager, next"
                   :page-size="pageSize"
                   :total="total"
                   :current-page.sync="currentPage"
                   :page-sizes="[10,20,30,50,100]"
                   @size-change="handleSizeChange"
                   @current-change="page">
    </el-pagination>
  </div>

</template>

<script>
import * as XLSX from "xlsx";
import FileSaver from 'file-saver';
export default {
  data() {
    return {
      tableData:null,
      currentPage: 1,
      pageSize: 10,
      total: '',
      key: '',
      value: '',
      ruleForm: {
        day1:'',
        day2:'',
        key: '',
        value: '',
        page: '',
        size: 10
      },
      rules: {
        key: [
          { required: true, message: '请选择字段', trigger: 'change' }
        ]
      }
    }
  },
  methods:{
    handleExport() {
      this.$confirm('该操作将导出所有列表数据，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let exportTable = XLSX.utils.table_to_book(document.querySelector('#myTable'))
        let exportTableOut = XLSX.write(exportTable, { bookType: 'xlsx', bookSST: true, type: 'array' })
        try {
          FileSaver.saveAs(new Blob([exportTableOut], { type: 'application/octet-stream' }), '业务单.xlsx')
        } catch (e) { if (typeof console !== 'undefined') console.log(e, exportTableOut) }
        return exportTableOut;

      }).catch(() => { });
    },
    //指定列求和
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }

        if (index === 4) {
          const values = data.map(item => Number(item[column.property]));
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index];
        }
      });
      return sums
    },
    addClass({row,column,rowIndex,columnIndex}){
      if (column.label === '业务审核'){
        if(row.businessAudit === '已审核'){
          return 'background: #95e195;';
        }
        else if(row.businessAudit === '未审核'){
          return 'background: #ec9494;';
        }
      }
      else if(column.label === '业务状态'){
        if(row.businessState === '已完成'){
          return 'background: #95e195;';
        }
        else if(row.businessState === '未完成'){
          return 'background: #ec9494;';
        }
      }
      else if(column.label === '是否收款'){
        if(row.collectionState === '未收款'){
          return 'background: #ec9494;';
        }
        else if(row.collectionState === '已收款'){
          return 'background: #95e195;';
        }
      }

    },
    handleSizeChange(val){
      this.currentPage = 1;
      this.pageSize = val;
      const _this = this
      let admin = JSON.parse(window.localStorage.getItem('access-admin'))
      if(_this.ruleForm.value == ''){
        //分页
        axios.get('http://1.14.190.205:8088/businessList/'+this.currentPage+'/'+ this.pageSize, {
          headers: {
            'token': admin
          }
        }).then(function (resp) {
          _this.tableData = resp.data.data
          _this.total = resp.data.total
        })
      } else {
        //根据字段查询
        _this.ruleForm.page = _this.currentPage
        _this.ruleForm.size = _this.pageSize
        axios.get('http://1.14.190.205:8088/searchBusiness',{
          params:_this.ruleForm,
          headers:{
            'token': admin
          }
        }).then(function (resp) {
          _this.tableData = resp.data.data
          _this.total = resp.data.total
        })
      }
    },
    submitForm(formName) {
      const _this = this
      let admin = JSON.parse(window.localStorage.getItem('access-admin'))
      //让翻页复原
      _this.currentPage = 1
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this
          _this.ruleForm.page = _this.currentPage
          _this.ruleForm.size = _this.pageSize
          axios.get('http://1.14.190.205:8088/searchBusiness',{
            params:_this.ruleForm,
            headers:{
              'token': admin
            }}).then(function (resp) {
            _this.tableData = resp.data.data
            _this.total = resp.data.total
          })
        }
      });
    },
    page(currentPage){
      const _this = this
      let admin = JSON.parse(window.localStorage.getItem('access-admin'))
      if(_this.ruleForm.value == ''){
        //分页
        axios.get('http://1.14.190.205:8088/businessList/'+currentPage+'/'+_this.pageSize, {
          headers: {
            'token': admin
          }
        }).then(function (resp) {
          _this.tableData = resp.data.data
          _this.total = resp.data.total
        })
      } else {
        //根据字段查询
        _this.ruleForm.page = _this.currentPage
        axios.get('http://1.14.190.205:8088/searchBusiness',{
          params:_this.ruleForm,
          headers:{
            'token': admin
          }
        }).then(function (resp) {
          _this.tableData = resp.data.data
          _this.total = resp.data.total
        })
      }
    },
    edit(row){
      //编辑
      localStorage.setItem('businessId',row.businessId)
      console.log(localStorage.getItem('businessId'))
      this.$router.push('/BusinessUpdate?id='+row.businessId)
    },
    finish(row){
      const _this = this
      let admin = JSON.parse(window.localStorage.getItem('access-admin'))
      this.$confirm('确认完成审核【'+row.companyName+'】吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(row.businessId)
        axios.get('http://1.14.190.205:8088/finishBusiness/'+row.businessId,{
          headers: {
            'token': admin
          }
        }).then(function (resp) {
          if(resp.data.code==200){
            _this.$alert('已完成审核', '', {
              confirmButtonText: '确定',
              callback: action => {
                _this.$router.go(0)
              }
            });
          }
        });
      });
    },
    del(row) {
      //删除
      const _this = this
      let admin = JSON.parse(window.localStorage.getItem('access-admin'))
      this.$confirm('确认删除【'+row.companyName+'】吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.delete('http://1.14.190.205:8088/deleteBusinessById/'+row.businessId,{
          headers: {
            'token': admin
          }
        }).then(function (resp) {
          if(resp.data.code==200){
            _this.$alert('已删除', '', {
              confirmButtonText: '确定',
              callback: action => {
                _this.$router.go(0)
              }
            });
          }
        });
      });
    }
  },
  created() {
    //初始化页面
    let admin = JSON.parse(window.localStorage.getItem('access-admin'))
    const _this = this
    axios.get('http://1.14.190.205:8088/businessList/1/'+_this.pageSize,{
      headers:{
        'token': admin
      }
    }).then(function (resp) {
      console.log(resp.data)
      _this.tableData = resp.data.data
      _this.total = resp.data.total
    })
  }
}
</script>