<template>
  <div style="margin-top: 60px;margin-left:330px;width: 1000px;height: 500px;border: 0px solid #434343;">
    <el-row :gutter="15">
      <el-form ref="formData" :model="formData" :rules="rules" size="small" label-width="100px">
        <el-col :span="24">
          <el-form-item label-width="180px" label="业务编号" prop="businessId">
            <el-input v-model="formData.businessId" readonly clearable :style="{width: '100%'}">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label-width="180px" label="操作业务客户名称" prop="company">
            <el-input v-model="formData.company" placeholder="请输入公司名称"  clearable :style="{width: '100%'}">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="业务类型" prop="contents">
            <el-checkbox-group v-model="formData.contents" size="medium">
              <el-checkbox v-for="(item, index) in contentsOptions" :key="index" :label="item.value"
                           :disabled="item.disabled">{{item.label}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label-width="120px" label="其他业务类型" prop="content2">
            <el-input v-model="formData.content2" clearable :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="客户资料" prop="materials">
            <el-checkbox-group v-model="formData.materials" size="medium">
              <el-checkbox v-for="(item, index) in materialsOptions" :key="index" :label="item.value"
                           :disabled="item.disabled">{{item.label}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="其他客户资料" prop="material2">
            <el-input v-model="formData.material2" clearable :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="交接人" prop="signer">
            <el-input v-model="formData.signer" placeholder="请输入交接人" clearable :style="{width: '100%'}">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="接收人" prop="operator">
            <el-input v-model="formData.operator" placeholder="请输入接收人" clearable :style="{width: '100%'}">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="年" prop="year">
            <el-input v-model="formData.year" clearable :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="月" prop="moon">
            <el-input v-model="formData.moon" clearable :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="日" prop="day">
            <el-input v-model="formData.day" clearable :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input v-model="formData.remark" type="textarea" :autosize="{minRows: 4, maxRows: 4}"
                      :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item size="large">
            <el-button type="primary" @click="submitForm('formData')">保存</el-button>
<!--            <el-button @click="resetForm('formData')">重置</el-button>-->
            <el-button @click="printForm('formData')">生成交接表</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formData: {
        businessId:'',
        company: '',
        contents: [],
        content2: '',
        materials: [],
        material2: '',
        signer: '',
        operator: '',
        year: '',
        moon: '',
        day: '',
        remark: '',
      },
      rules: {
        company: [{
          required: true,
          message: '请输入公司名称',
          trigger: 'blur'
        }],
        contents: [{
          required: true,
          type: 'array',
          message: '请至少选择一个业务类型',
          trigger: 'change'
        }],
        content2: [],
        materials: [{
          required: true,
          type: 'array',
          message: '请至少选择一个客户资料',
          trigger: 'change'
        }],
        material2: [],
        signer: [{
          required: true,
          message: '请输入交接人',
          trigger: 'blur'
        }],
        operator: [{
          required: true,
          message: '请输入接收人',
          trigger: 'blur'
        }],
        year: [{
          required: true,
          message: '',
          trigger: 'blur'
        }],
        moon: [{
          required: true,
          message: '',
          trigger: 'blur'
        }],
        day: [{
          required: true,
          message: '',
          trigger: 'blur'
        }],
      },
      contentsOptions: [{
        "label": "公司设立",
        "value": "公司设立"
      }, {
        "label": "地址变更",
        "value": "地址变更"
      }, {
        "label": "法人变更",
        "value": "法人变更"
      }, {
        "label": "股权变更",
        "value": "股权变更"
      }, {
        "label": "名称变更",
        "value": "名称变更"
      }, {
        "label": "税务变更",
        "value": "税务变更"
      }, {
        "label": "税务注销",
        "value": "税务注销"
      }, {
        "label": "工商注销",
        "value": "工商注销"
      }, {
        "label": "进出口资质",
        "value": "进出口资质"
      }, {
        "label": "食品证",
        "value": "食品证"
      }, {
        "label": "卫生证",
        "value": "卫生证"
      }, {
        "label": "道路运输许可证",
        "value": "道路运输许可证"
      }, {
        "label": "地址挂靠",
        "value": "地址挂靠"
      }, {
        "label": "ISO认定",
        "value": "ISO认定"
      }, {
        "label": "商标注册",
        "value": "商标注册"
      }, {
        "label": "专利注册",
        "value": "专利注册"
      }, {
        "label": "银行开户",
        "value": "银行开户"
      }, {
        "label": "香港公司注册",
        "value": "香港公司注册"
      }, {
        "label": "香港公司年审",
        "value": "香港公司年审"
      }, {
        "label": "香港公司审计",
        "value": "香港公司审计"
      }, {
        "label": "环保批文",
        "value": "环保批文"
      }, {
        "label": "网络经营许可证",
        "value": "网络经营许可证"
      }],
      materialsOptions: [{
        "label": "营业执照正本",
        "value": "营业执照正本"
      }, {
        "label": "营业执照副本",
        "value": "营业执照副本"
      }, {
        "label": "公章",
        "value": "公章"
      }, {
        "label": "财务章",
        "value": "财务章"
      }, {
        "label": "发票章",
        "value": "发票章"
      }, {
        "label": "法人章",
        "value": "法人章"
      }, {
        "label": "法人身份证",
        "value": "法人身份证"
      }, {
        "label": "股东身份证",
        "value": "股东身份证"
      }, {
        "label": "数字证书",
        "value": "数字证书"
      }],
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    submitForm(formName) {
      const _this = this
      console.log(_this.formData);
      this.$refs[formName].validate(valid => {
        let admin = JSON.parse(window.localStorage.getItem('access-admin'))
        if(valid){
          console.log(_this.formData);
          axios.post('http://localhost:8088/saveHandover',_this.formData,{
            headers:{
              'token': admin
            }
          }).then(function (resp) {
            if(resp.data.code == 200){
              _this.$alert(_this.formData.company+'保存成功', '', {
                confirmButtonText: '确定',
                callback: action => {
                  _this.$router.push('/HandoverTablePage')
                }
              });
            }else if(resp.data.code == 400 || resp.data.code == 401){
              _this.$alert('账号越权,请重新登录','',{
                confirmButtonText: '确定',
                callback: action => {
                  _this.$router.push('/login')
                }
              });
            }
          })
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    printForm(formName){
      const _this = this
      console.log(_this.formData);
      this.$refs[formName].validate(valid => {
        let admin = JSON.parse(window.localStorage.getItem('access-admin'))
        if(valid){
          console.log(_this.formData);
          axios.post('http://1.14.190.205:8088/printHandover',_this.formData,{
            headers:{
              'token': admin
            }
          }).then(function (resp) {
            if(resp.data.code == 200){
              _this.$confirm('【'+_this.formData.company+'】 交接表已生成，是否下载', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(() => {
                const config = {
                  method: 'get',
                  url: 'http://1.14.190.205:8088/downHandover',
                  headers: {
                    //和后端设置的一样
                    'token': admin,
                    'Content-Type': 'application/octet-stream;charset=UTF-8'
                  },
                  responseType: 'blob'
                };
                axios(config).then(response => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', '亿企富业务交接表.docx');
                  document.body.appendChild(link);
                  link.click();
                })
                this.$message({
                  type: 'success',
                  message: '下载成功!'
                });
                //_this.$router.push('/SalesmanBusiness')
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消'
                });
              });
              // _this.$alert(_this.formData.company+' 生成交接表成功，是否下载交接表', '', {
              //   confirmButtonText: '确定',
              //   cancelButtonText: '取消',
              //   callback: action => {
              //     const config = {
              //       method: 'get',
              //       url: 'http://localhost:8088/downHandover',
              //       headers: {
              //         //和后端设置的一样
              //         'Content-Type': 'application/octet-stream;charset=UTF-8'
              //       },
              //       responseType: 'blob'
              //     };
              //     axios(config).then(response => {
              //       const url = window.URL.createObjectURL(new Blob([response.data]));
              //       const link = document.createElement('a');
              //       link.href = url;
              //       link.setAttribute('download', '亿企富业务交接表.docx');
              //       document.body.appendChild(link);
              //       link.click();
              //     })
              //     _this.$router.push('/SalesmanBusiness')
              //   },
              // }
              // );
            }else if(resp.data.code === 400 || resp.data.code === 401){
              _this.$alert('账号越权,请重新登录','',{
                confirmButtonText: '确定',
                callback: action => {
                  _this.$router.push('/login')
                }
              });
            }
          })
        }
      });
    },
  },
  created() {
    const _this = this;
    console.log(_this.$route.query.id)
    let admin = JSON.parse(window.localStorage.getItem('access-admin'))
    axios.get('http://1.14.190.205:8088/findHandover/'+_this.$route.query.id,{
      headers:{
        'token': admin
      }
    }).then(function (resp){
      console.log(resp.data);
      console.log(resp.data.code);
      if(resp.data.code == 200){
        _this.formData = resp.data.handover;
      } else if(resp.data.code == 210){
        _this.formData.businessId = _this.$route.query.id;
        _this.formData.company = _this.$route.query.name;
      } else if(resp.data.code == 401){
        //没有权限
      }else{
        //没有登录
      }
    })
  }
}

</script>
<style>
</style>
